// SessionLab variables
$font-family-light: 'Inter';

$display-font-sizes: (
  1: 3.75rem,
  2: 2.875rem,
  3: 2.125rem,
  4: 1.375rem,
  5: 1.25rem,
  6: 2.5rem // currently default value,
);

@at-root {
  :root {
    --fa-li-width: 1.25rem;
  }
}

$headerHeight: 55px;

$navbar-light-toggler-border-color: none;

;@import "sass-embedded-legacy-load-done:2058";