.pebble-profile {
  background-image: url('images/elements/pebble-small-green.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 17px;
  max-width: 125px;
  img {
    position: relative;
    top: -17px;
    padding: 0 3px 13px 8px;
  }
}

;@import "sass-embedded-legacy-load-done:2155";