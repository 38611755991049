@mixin text-font-light() {
  font-family: $font-family-light, $font-family-sans-serif;
  font-weight: 300;
}

@mixin surrounded-by-line() {
  display: flex;
  flex-direction: row;

  &::before,
  &::after {
    content: '';
    flex: 1 1;
    border-bottom: 0.0625rem solid var(--bs-border-color);
    margin: auto;
  }

  &::before {
    margin-right: 0.7rem;
  }

  &::after {
    margin-left: 0.7rem;
  }
}

;@import "sass-embedded-legacy-load-done:2072";